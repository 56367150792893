import React, { useState, useContext, useEffect } from 'react';
import Web3Context from "../context/Web3Context";
import { motion, useReducedMotion } from 'framer-motion';
import { BigNumber, utils } from 'ethers';
import { useSPresale } from '../context/PresaleHandle';
import CardRefPresale from './CardRefPresale';


function PresalePrivada() {


  const [recaudacion, setRecaudacion] = useState(0);
  const maxValue = 1.5;
  const [amount, setamount] = useState(0);
  const [bbh, setbbh] = useState(0);
  const HARCAP = 200;

  const {  invest, allData, userData, balanceOf, withdrawPrivate } =
    useSPresale();
  const { accounts, isLoaded, setupdate, update1 } = useContext(Web3Context);
  const [investAmount, setinvestAmount] = useState(BigNumber.from(0));
  const [tokenAmount, settokenAmount] = useState(BigNumber.from(0));

  useEffect(() => {
    //setRecaudacion(presaleState?.HARCAP);
    setRecaudacion(allData?.totalInvested_);
    // setRecaudacion(100);
    return () => {};
  }, [allData]);

  useEffect(() => {
    console.log(amount);
    setbbh(amount * 165);
    return () => {};
  }, [amount]);

  function percentage(input) {
    return (input * 100) / 80;
  }
  // useEffect(() => {
  //   // percentage
  //   return () => {};
  // }, [presaleState]);

  function buyToken(amount_) {
    invest(amount_, true);
    console.log(amount_);
  }
  // buyer:"",
  //        tokenAmount:"",
  //        investAmount:"",
  //        hasWithdrawn:"",

  const [acount_, setacount_] = useState('0x');

  useEffect(() => {
    console.log();
    const start = '0X...';
    const end = accounts.slice(38);
    setacount_(`${start}...${end}`);
    return () => {};
  }, [accounts]);

  return (
    <>
    <div className=" bg-cover bg-center bg-fixed bg-black"
      style={{ backgroundImage: `url(/fondo.png)`}} >



      <div className="mx-auto grid  max-w-7xl items-center  lg:grid-cols-2">
        <div
          className="relative  mt-12 px-4"
        
        >
        <div/>
        </div>
        <div className="mt-10 mb-20" >
          <div className="max-w-4xl rounded-lg bg-gray-200/50  p-3 text-[#9900cb]">
          <div className="">
        <motion.div>
          <div>

            <div className="my-6 flex justify-center">
            <img src="/private-presale.png" className="h-auto w-52" />
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
              <div className=" px-12 pb-8 sm:px-3">
                <div>
               
                 
                  <div className="mt-6 ">                    
                    <button 
                    className="bhover:scale-105 block w-full text-center rounded-lg border border-transparent focus:shadow-outline-orange bg-yellow-400  px-6 py-3 sm:text-lg text-base font-semibold leading-6 text-black focus:outline-none focus:border-yellow-600 transition-all ease-in-out duration-150 transform"
                    onClick={() => withdrawPrivate()}
                    >
                      Claim
                    </button>
                  </div>

                  <div className="mt-6 flex w-full justify-center shadow-sm">
                  </div>
                  <p className="mt-4 text-center text-xl font-bold text-black">
                    $BILL price: 0.00047 BNB <br /> Only Participation: 0.1-3 BNB
                    <br />
                    Hardcap: 100 BNB
                  </p>
               
                  <div className="mt-10">
                    <label
                      htmlFor="number"
                      className=" text-md my-2 block text-center font-medium leading-5 text-black"
                    >
                      You will receive
                    </label>
                    <p className="block w-full rounded-lg  bg-slate-900 px-2 py-2 text-center font-bold text-white sm:text-sm">
                      {amount * 2250} BILL
                    </p>
                  </div>
                  <div className="-mt-px flex divide-x divide-[#8f80ba]">
                    <div className="flex w-0 flex-1 flex-col justify-center">
                      <a className="items-center justify-center rounded-bl-lg border border-transparent py-4  text-center text-sm font-medium text-slate-100 ">
                        <span className="">Your Invest:</span>
                        <p>{userData?.totalDeposits_}</p>
                      </a>
                    </div>
                    <div className="-ml-px flex w-0 flex-1 flex-col justify-center">
                      <a className="items-center justify-center rounded-bl-lg border border-transparent py-4  text-center text-sm font-medium text-slate-100 ">
                        <span className="">Your Tokens:</span>
                        <p>{userData?.balance_}</p>
                      </a>
                    </div>
                  </div>
                  <span className="mt-6 flex justify-center text-sm font-semibold leading-tight text-slate-100 md:text-base">
                    Collect: {recaudacion} {'BNB'}
                  </span>
                  <div className="mt-1 flex justify-center">
                    <div className="flex h-full w-60 items-center lg:flex-row ">
                      <div className="text-md mb-0 flex items-center justify-center uppercase text-gray-500">
                        {/* <span className="mr-2">completed</span> */}
                      </div>
                      <div className="relative h-1 w-full max-w-lg rounded-full bg-slate-800">
                        <motion.div
                          animate={{
                            width: `${percentage(
                              Number.parseInt(recaudacion),
                            )}%`,
                          }}
                          initial={{
                            width: `${percentage(
                              Number.parseInt(recaudacion),
                            )}%`,
                          }}
                          transition={{
                            duration: 0.5,
                            type: 'spring',
                            mass: 0.5,
                          }}
                          className="h-1 bg-green-600 transition-transform duration-75 ease-in-out"
                        />
                        {/* <div
          css={{left: '75%'}}
          className={`w-5 h-5 -mt-3 rounded-full absolute border-2 border-gray-300 bg-background ${
            moduleProgress.percentComplete >= 75 ? 'border-blue-500' : ''
          }`}
        /> */}
                        <div
                          style={{ left: '99%' }}
                          className={`absolute -mt-3 h-5 w-5 rounded-full border-2  bg-white `}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
         
          </div>
        </div>
      </div>
      </div>
    </>
  );
}

export default PresalePrivada;
