import React, { useContext, useState, useEffect } from 'react';
import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/solid';
import TokenHandle from '../context/TokenHandle';
import clsx from 'clsx';
import { utils } from 'ethers';
import refHandle from '../hooks/utils';
import Modal from './Modal';
import { useCountdown } from '../hooks/useCountdown';
import PriceToken from './PriceToke';
function Stake() {
  const [amount, setamount] = useState(0);
  const [referred, setReferred] = useState('');
  const [errorPrice, setErrorPrice] = useState('');
  const [ModalOpen, setModalOpen] = useState(false);
  const { invest, userData, allData, isApprove, approveHandle, claimHandle } =
    useContext(TokenHandle);

  useEffect(() => {
    const referred_ = refHandle();
    if (referred_ != '0x373Ea14E600A474F30fc27b39dAd0253d3094dcf')
      setReferred(referred_);
  }, []);

  useEffect(() => {
    setErrorPrice('');
    if (amount < 21) setErrorPrice(`The minimum invest is 21 USDT`);
  }, [amount]);

  const { timerDays, timerHours, timerMinutes, timerSeconds } = useCountdown([
    2024, 1, 1, 20,
  ]);
  useEffect(() => {
    return () => {};
  }, [timerDays]);

  const balance = parseFloat(userData.balance_) || 0;
  const totalWithdrawn_ = parseFloat(userData.totalWithdrawn_) || 0;
  const totalDeposits = parseFloat(userData.totalDeposits_) || 0;
  const bonusWithdraw_c = parseFloat(userData.bonusWithdrawC) || 0;

  let progressPercentage;

  if (totalDeposits === 0) {
    progressPercentage = 0;
  } else if (
    !isNaN(balance) &&
    !isNaN(totalWithdrawn_) &&
    !isNaN(bonusWithdraw_c)
  ) {
    progressPercentage =
      ((totalWithdrawn_ + balance - bonusWithdraw_c) * 100) / totalDeposits;
  } else {
    progressPercentage = 0;
  }

  return (
    <>
      <Modal open={ModalOpen} setOpen={setModalOpen}>
        <div className="mt-3">
          <div className="text-xl font-medium text-white text-center">
            Claim your 21 Bills Now!
          </div>
          <p className="text-purple-200 text-sm mt-2">
            As a thank you for your support, we are excited to offer 21 21 Bills
            tokens. Click below to claim them now and be part of our growing
            community!
          </p>
          <button
            type="button"
            onClick={claimHandle}
            className="mt-4 w-full bg-slate-100 hover:bg-slate-50 text-black font-bold py-2 px-4 rounded"
          >
            Claim
          </button>
        </div>
      </Modal>
      <div className="xl:flex-shrink-0 xl:w-68  ">
        <div className="py-6 pl-4 pr-6 sm:pl-6 lg:pl-8 xl:pl-0">
          <div className="flex items-center justify-between">
            <div className="flex-1 space-y-8">
              {/* Stake */}
              <div className="mt-8  rounded-lg mx-auto  max-w-2xl lg:w-96">
                <div className="px-4 py-8 bg-center  bg-cover bg-local bg-2 w-full shadow sm:rounded-lg sm:px-10">
                  <div className="space-y-6">
                    <div>
                      <div className="mt-1">
                        <div>
                          <label
                            htmlFor="amount"
                            className="block mb-1 ml-1 text-sm uppercase font-bold text-[#9f0]"
                          >
                            Enter Amount
                          </label>
                          <div className="relative mt-1 rounded-md shadow-sm">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                              <img
                                alt="asdasdasd"
                                src="/usdt-purple.png"
                                className="h-5 w-5 text-gray-400"
                              />
                            </div>
                            <input
                              onChange={(e) => {
                                if (e.target.value == '' || e.target.value == 0)
                                  setamount('21');
                                else setamount(e.target.value);
                              }}
                              className={clsx(
                                'rounded-md',
                                {
                                  'border-red-300 text-red-500 placeholder-red-300 focus:ring-red-500 focus:border-red-500 text-center font-bold bg-black text-white appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400  sm:text-sm':
                                    errorPrice,
                                },
                                {
                                  'text-center font-bold bg-[#9f0] text-[#90c] appearance-none block w-full px-3 py-2   rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-400 focus:border-yellow-400 sm:text-sm':
                                    !errorPrice,
                                }
                              )}
                              id="stake"
                              name="stake"
                              type="number"
                              required
                            />
                          </div>
                        </div>

                        {errorPrice && (
                          <>
                            <div className="absolute mt-2 w-60">
                              <p
                                className="text-xs text-red-300"
                                id="price-error"
                              >
                                {errorPrice}
                              </p>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="mt-9">
                        <label
                          htmlFor="referred"
                          className="block mb-1 ml-1 text-sm uppercase font-bold text-primary"
                        >
                          Price BILL
                        </label>
                        <div
                          className="block w-full bg-[#12181f] rounded-md border-0 py-1.5 text-[#abb5bf] shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-200 sm:text-sm sm:leading-6"
                          id="referred"
                          name="referred"
                          type="text"
                          required
                        >
                          <PriceToken /> USD
                        </div>
                      </div>

                      <div className="mt-4">
                        <label
                          htmlFor="referred"
                          className="block mb-1 ml-1 text-sm uppercase font-bold text-primary"
                        >
                          Referred
                        </label>
                        <input
                          className="block w-full px-3 py-2  text-xs font-bold text-center bg-[#9f0] text-[#90c]  rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-400  sm:text-xs"
                          id="referred"
                          name="referred"
                          type="text"
                          required
                          onChange={(e) => {
                            setReferred(e.target.value);
                          }}
                          defaultValue={referred}
                        />
                      </div>
                    </div>

                    <div className="hidden">
                      {isApprove ? (
                        <button
                          onClick={() => invest(amount, referred)}
                          className="flex hover:brightness-110 justify-center w-full btn-primary py-2 rounded-md"
                        >
                          INVEST
                        </button>
                      ) : (
                        <button
                          onClick={approveHandle}
                          type="button"
                          className="flex hover:brightness-110 justify-center w-full btn-primary py-2 rounded-md"
                        >
                          Approve
                        </button>
                      )}

                      {/*
                          <div className="text-center text-white mt-3 ">
                        <h1> Countdown Airdrop:</h1>
                        {timerDays} Days {timerHours} Hours{" "}
                      {timerMinutes} Min
                      </div>
             
                      <button
                        onClick={() => setModalOpen(true)}
                        className='flex hover:brightness-110 justify-center w-full bg-black text-white py-2 rounded-md'
                      >
                        <svg
                          stroke='currentColor'
                          fill='currentColor'
                          strokeWidth='0'
                          viewBox='0 0 512 512'
                          className='w-5 h-5 mr-2'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path d='M383.5 192c.3-5.3 .5-10.6 .5-16c0-51-15.9-96-40.2-127.6C319.5 16.9 288.2 0 256 0s-63.5 16.9-87.8 48.4C143.9 80 128 125 128 176c0 5.4 .2 10.7 .5 16H240V320H208c-7 0-13.7 1.5-19.7 4.2L68.2 192H96.5c-.3-5.3-.5-10.6-.5-16c0-64 22.2-121.2 57.1-159.3C62 49.3 18.6 122.6 4.2 173.6C1.5 183.1 9 192 18.9 192h6L165.2 346.3c-3.3 6.5-5.2 13.9-5.2 21.7v96c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V368c0-7.8-1.9-15.2-5.2-21.7L487.1 192h6c9.9 0 17.4-8.9 14.7-18.4C493.4 122.6 450 49.3 358.9 16.7C393.8 54.8 416 112.1 416 176c0 5.4-.2 10.7-.5 16h28.3L323.7 324.2c-6-2.7-12.7-4.2-19.7-4.2H272V192H383.5z'></path>
                        </svg>
                        AIRDROP
                      </button>
                      
                      
                      */}
                    </div>
                  </div>
                </div>
              </div>

              {/*Statitics */}
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-1">
                <div className="relative bg-2 flex items-center px-6 py-5 space-x-3 rounded-lg shadow-sm ">
                  <div className="flex-1 min-w-0">
                    <span className="absolute inset-0" aria-hidden="true" />
                    <p className="text-md font-black text-[#9f0] uppercase">
                      Your Balance
                    </p>
                    <div className="mt-2">
                      <div className="relative flex items-start py-3 px-3 rounded-md bg-[#9f0] text-[#90c] ">
                        <div className="min-w-0 flex-1 text-sm">
                          <label
                            htmlFor={`side-`}
                            className="select-none font-medium "
                          >
                            <img
                              alt="asdasd"
                              src="/usdt-purple.png"
                              className="h-7 w-7 "
                            />{' '}
                          </label>
                        </div>
                        <div className="ml-3 mt-2 flex h-5 font-black items-center ">
                          {userData.balance_} USDT
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="mt-4 h-2.5 relative  overflow-hidden">
                        <div
                          className="h-2.5 rounded-full bg-[#9f0] overflow-hidden"
                          style={{
                            // Asegura que el ancho no exceda el 100% del contenedor
                            width: `${Math.min(progressPercentage / 2, 100)}%`,
                          }}
                        />
                      </div>
                    </div>
                    <p className="mt-2 break-all text-white">
                      {progressPercentage <= 200
                        ? `Progreso: ${progressPercentage.toFixed(2)}%`
                        : 'You have reached the goal!'}
                    </p>
                  </div>
                </div>
                <div className=" relative  bg-center pb-12  flex items-center px-6 py-5 space-x-3 bg-2 rounded-lg shadow-sm ">
                  <div className="flex-1 min-w-0">
                    <div className="flex justify-center">
                      <img
                        alt=""
                        src="https://s3.magic-api.xyz/21-dollars/logo.png"
                        className="w-48 h-20"
                      />
                    </div>

                    <span className="absolute inset-0" aria-hidden="true" />
                    <p className="text-md mt-5 font-black text-[#9f0] uppercase">
                      Contract Balance
                    </p>
                    <div className="mt-2">
                      <div className="relative flex items-start py-3 px-3 rounded-md bg-[#9f0] text-[#90c] ">
                        <div className="min-w-0 flex-1 text-sm">
                          <label
                            htmlFor={`side-`}
                            className="select-none font-medium text-gray-700"
                          >
                            <img
                              alt="asdasdasd"
                              src="/usdt-purple.png"
                              className="h-7 w-7 "
                            />{' '}
                          </label>
                        </div>
                        <div className="ml-3 mt-2 flex h-5 font-black items-center ">
                          {allData.balance_} USDT
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Stake;
