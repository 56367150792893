import React, { useContext, useState, useEffect } from "react";

import TokenHandle from "../../context/TokenHandle";

const people_ = [
  { name: "LINE 1", title: "0" },
  { name: "LINE 2", title: "0" },
  { name: "LINE 3", title: "0" },
  { name: "LINE 4", title: "0" },
  { name: "LINE 5", title: "0" },
  { name: "LINE 6", title: "0" },
  { name: "LINE 7", title: "0" },
  { name: "LINE 8", title: "0" },
  { name: "LINE 9", title: "0" },
  { name: "LINE 10", title: "0" },
  { name: "LINE 11", title: "0" },
  { name: "LINE 12", title: "0" },
  { name: "LINE 13", title: "0" },
  { name: "LINE 14", title: "0" },
  { name: "LINE 15", title: "0" },
  { name: "LINE 16", title: "0" },
  { name: "LINE 17", title: "0" },
  { name: "LINE 18", title: "0" },
  { name: "LINE 19", title: "0" },
  { name: "LINE 20", title: "0" },
  // More people...
];
function RefHandle() {
  const { withdraw, reinvestment, userData, withdraw2 } =
    useContext(TokenHandle);
  const [people, setpeople] = useState(people_);

  useEffect(() => {
    if (userData.referrerCount_.length == 0) return;
    let peopleHandle_ = people_.map((value, i) => {
      return {
        name: value.name,
        title: userData.referrerCount_[i],
      };
    });

    setpeople(peopleHandle_);

    return () => {};
  }, [userData]);

  return (
    <div className="relative mt-12 sm:mt-16 lg:mt-4">
      <div className="">
        <div className="">
          <h3 className="text-2xl uppercase font-extrabold text-slate-100 tracking-tight sm:text-3xl text-center mb-6">
          Referral Lines
          </h3>
          <div className="flex flex-col  mt-4 lg:px-0 px-4">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:mx-1">
              <div className="py-2 align-middle inline-block min-w-full ">
                <div className="overflow-y-auto h-60 border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-900">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-green-400 uppercase tracking-wider"
                        >
                          REFERENCE
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-green-400 uppercase tracking-wider"
                        >
                          Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {people.map((person, personIdx) => (
                        <tr
                          key={personIdx}
                          className={
                            personIdx % 2 === 0 ? "bg-gray-800" : "bg-gray-700"
                          }
                        >
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-300">
                            {person.name}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-100">
                            {person.title}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RefHandle;
