import React, { useContext, useState, useEffect } from 'react';
import CardRef from './CardRef';
import Stake from './Stake';
import TokenHandle from '../context/TokenHandle';
import ModalInfoCompesated from './ModalInfoCompesated';
import ModalWithdrawFrequent from './ModalWithdrawFrequent';
import ModalTableReferral from './ModalTableReferral';
import { useCountdown } from '../hooks/useCountdown';
import Banner from './Banner';
import Navbar from './Navbar';
import PriceToken from './PriceToke';
import { formatRange } from '../utils/utils';
function InvestNow() {
  const { withdraw, reinvestment, userData, withdraw2, allData } =
    useContext(TokenHandle);
  const [dashboardTotal, setDashboardTotal] = useState([
    {
      name: 'Deposit',
      value: 0,
    },
    {
      name: 'Bonus',
      value: 0,
    },
    {
      name: 'Withdraw',
      value: 0,
    },
    {
      name: 'Reinvest',
      value: 0,
    },
    // More people...
  ]);
  useEffect(() => {
    console.log(userData, 'userData');
    const dashboardTotal_ = [
      {
        name: 'Deposit',
        value: userData.totalDeposits_,
      },
      {
        name: 'Withdraw',
        value: userData.totalWithdrawn_,
      },
      {
        name: 'Bonus',
        value: userData.machineBalance,
      },
      {
        name: 'Reinvest',
        value: userData.totalreinvest_,
      },
      // More people...
    ];
    setDashboardTotal(dashboardTotal_);

    return () => {};
  }, [userData]);

  const { timerDays, timerHours, timerMinutes, timerSeconds } = useCountdown([
    2023, 11, 30, 18,
  ]);
  useEffect(() => {
    return () => {};
  }, [timerDays]);

  return (
    <>
      <div className="md:aspect-video bg-purple-800  fixed bottom-1 z-[999999] flex  flex-col justify-between overflow-hidden px-6 py-4 sm:rounded-lg md:px-12 md:py-6">
        <h1 className="text-xs font-bold text-white">
          Price BILL: <PriceToken /> $
        </h1>
        <h1 className="text-xs font-bold text-white">
          {formatRange(
            Number(userData.totalDeposits_),
            Number(userData?.referrerCount_[0]),
            Number(userData?.referrerCount_[1]),
            Number(userData?.referrerCount_[2]),
            Number(userData?.referrerCount_[3])
          )}
        </h1>
      </div>
      <Banner />
      <Navbar />
      <CardRef />

      <div
        id="dashboard"
        className="flex-grow w-full max-w-7xl mx-auto xl:px-8 lg:flex "
      >
        {/* Left sidebar & main wrapper */}
        <div className="flex-1 min-w-0  xl:flex lg:mb-0 mb-20">
          {/* Stake */}
          <Stake />

          {/* Dashboard */}
          <div className=" lg:min-w-0 lg:flex-1">
            <div className="lg:mt-16 grid grid-cols-1 gap-4 items-start lg:grid-cols-1 lg:gap-8 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              {/* Left column */}
              <div>
                <div className="your-bg mt-2 relative rounded-lg  px-6 py-7 shadow-sm flex items-center space-x-3 ">
                  <div className="flex-1 min-w-0">
                    <p className="text-md uppercase font-bold text-slate-900">
                      Pasive Income Total Balance
                    </p>
                    <div className="relative flex items-start py-3 px-3 ">
                      <div className="min-w-0 pt-3 flex-1 text-sm">
                        <label
                          htmlFor={`side-`}
                          className="select-none mt-2 font-medium text-gray-700"
                        >
                          <img
                            alt="ADFASDFASDF"
                            src="/usdt-green.png"
                            width={80}
                            height={50}
                            className="w-16"
                          />{' '}
                        </label>
                      </div>
                      <div className="ml-3 lg:text-3xl text-xl flex mt-7 font-black items-center text-black">
                        {Number(userData.depositBalance).toFixed(5)} USDT
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                  {dashboardTotal.map((total, i) => (
                    <div
                      key={i}
                      className="your-bg pb-8   mt-2 relative rounded-lg  px-6 py-5 shadow-sm flex items-center space-x-3 "
                    >
                      <div className="flex-1 min-w-0">
                        <p className="text-md   font-bold text-black uppercase">
                          Total <span className="text-black">{total.name}</span>
                        </p>
                        <div className="relative flex items-start py-3 px-3 ">
                          <div className="min-w-0 pt-3 flex-1 text-sm">
                            <label
                              htmlFor={`side-`}
                              className="select-none mt-2 font-medium text-gray-700"
                            >
                              <img
                                alt="ASDFASDFASDF"
                                src="/usdt-green.png"
                                width={50}
                                height={50}
                              />{' '}
                            </label>
                          </div>
                          <div className="ml-3 text-xl flex mt-7 font-black items-center text-black">
                            {Number(total.value).toFixed(3)} USDT
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="your-bg mt-2 relative rounded-lg   px-6 py-7 shadow-sm flex items-center space-x-3 ">
                  <div className="flex-1 min-w-0">
                    <p className="text-md  font-bold text-slate-900">
                      ANTIWHALE SYSTEM
                      <br />
                      <span className="font-black text-green-900 text-sm">
                        Limit Withdraw in the Smart Contract
                      </span>
                    </p>
                    <div className="relative flex items-start py-3 px-3 ">
                      <div className="min-w-0 pt-3 flex-1 text-sm">
                        <label
                          htmlFor={`side-`}
                          className="select-none mt-2 font-medium text-gray-700"
                        >
                          <img
                            alt="ADFASDFASDF"
                            src="/usdt-green.png"
                            width={80}
                            height={50}
                            className="w-16"
                          />{' '}
                        </label>
                      </div>
                      <div className="ml-3 lg:text-3xl text-xl flex mt-7 font-black items-center text-black">
                        {Number(userData.maxWithdrawTime).toFixed(5)} USDT
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-3 grid grid-cols-1 gap-6 sm:grid-cols-1 lg:grid-cols-3 hidden">
                <ModalWithdrawFrequent onClick={() => withdraw()} />
                <ModalInfoCompesated onClick={() => withdraw2()} />
                <button
                  onClick={() => reinvestment()}
                  type="button"
                  className="w-full"
                >
                  <img src="/reinvest.png" className="w-full" />
                </button>
              </div>
              <ModalTableReferral />
            </div>
          </div>
        </div>
        {/* Activity feed */}
      </div>
      <div className="max-w-7xl mx-auto pb-20 lg:px-8 px-4 lg:mt-0 -mt-8">
        <div className="relative  w-full max-w-7xl mx-auto px-4 rounded-lg bg-2   py-5 shadow-sm flex items-center space-x-3  focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
          <div className="w-full pb-5 sm:flex sm:items-center sm:justify-between">
            <div className="flex-1 min-w-0">
              <p className="text-2xl uppercase font-bold text-[#9f0]">
                Total Users
              </p>
              <div className="flex items-center mt-3">
                <div className="mr-4 flex-shrink-0 self-center">
                  <img
                    alt="ASDFASDFASDF"
                    src="/referrals.png"
                    width={50}
                    height={50}
                  />
                </div>
                <div>
                  <h4 className="text-2xl font-bold text-[#9f0]">
                    {' '}
                    {allData.totalUsers_}
                  </h4>
                </div>
              </div>
            </div>
            <div className="flex-1 min-w-0">
              <p className="text-2xl uppercase font-bold text-[#9f0]">
                Your Referral
              </p>
              <div className="flex items-center mt-3">
                <div className="mr-4 flex-shrink-0 self-center">
                  <img
                    alt="ASDFASDFASDF"
                    src="/referrals.png"
                    width={50}
                    height={50}
                  />
                </div>
                <div>
                  <h4 className="text-2xl font-bold text-[#9f0]">
                    {' '}
                    {userData.referrerCountNumber}
                  </h4>
                </div>
              </div>
            </div>
            <div className="mt-12 sm:mt-0 sm:ml-4">
              <div className="flex-1 min-w-0">
                <p className="text-2xl uppercase font-bold text-[#9f0]">
                  Referral Rewards
                </p>
                <div className="flex items-center mt-3">
                  <div className="mr-4 flex-shrink-0 self-center">
                    <img
                      alt="ASDFASDFASDF"
                      src="/usdt.png"
                      width={50}
                      height={50}
                    />
                  </div>
                  <div>
                    <h4 className="text-2xl font-bold text-[#9f0]">
                      {' '}
                      {userData.machineBalance} USDT
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center pb-12">
        <img
          alt="ASDFASDFASDF"
          src="https://s3.magic-api.xyz/21-dollars/anti.png"
          className="w-auto h-28"
        />
      </div>
    </>
  );
}

export default InvestNow;
