export function formatRange(num, level1Referrals, level2Referrals, level3Referrals,level4Referrals) {
    if (num >= 50000 && level1Referrals >= 5 && level2Referrals >= 25 && level3Referrals >= 125 && level4Referrals >= 500) {
        return 'Range: Starbillion 3';
    }

    if (num >= 25000 && level1Referrals >= 5 && level2Referrals >= 25 && level3Referrals >= 100) {
        return 'Range: Starbillion 2';
    }
    if (num >= 15000 && level1Referrals >= 5 && level2Referrals >= 25) {
        return 'Range: Starbillion 1';
    }
}