/* eslint-disable */
import React, { createContext, useState, useEffect, useContext } from "react";
import Web3Context from "./Web3Context";
import {
  address,
  useAirdrop,
  useBnbi,
  useBUSD,
} from "../hooks/useContracts.js";
import { BigNumber, constants, ethers, utils } from "ethers";
import { useToasts } from "react-toast-notifications";
import refHandle from "../hooks/utils";
import { toast } from 'react-hot-toast';
const TonkenContext = createContext();

const TokenProvider = ({ children }) => {
  const { addToast } = useToasts();
  const { accounts, isLoaded, setupdate, update, etherJSProvider } =
    useContext(Web3Context);
  const [update_, setupdate_] = useState(0);
  const [userData, setuserData] = useState({
    totalWithdrawn_: 0,
    totalDeposits_: 0,
    totalreinvest_: 0,
    balance_: 0,
    nextAssignment_: 0,
    amountOfDeposits: 0,
    checkpoint: 0,
    depositBalance: 0,
    isUser_: false,
    referrer_: "",
    referrerCount_: [],
    referrerCountNumber: 0,
    machineBalance: 0,
    deltaWithdrawDate: 0,
    withdrawFee: 0,
    maxWithdrawTime: 0,
    maxWithdraw: 0,
    bonusWithdrawC: 0,
  });

  // uint totalWithdrawn_,
  // uint totalDeposits_,
  // uint totalreinvest_,
  // uint balance_,
  // uint nextAssignment_,
  // uint amountOfDeposits,
  // uint checkpoint,
  // bool isUser_,
  // address referrer_,
  // uint[MACHINEBONUS_LENGTH] memory referrerCount_
  const [allData, setallData] = useState({
    totalUsers_: 0,
    totalInvested_: 0,
    totalReinvested_: 0,
    totalWithdrawn_: 0,
    totalDeposits_: 0,
    balance_: 0,
    roiBase: 0,
    maxProfit: 0,
    minDeposit: 0,
    daysFormdeploy: 0,
  });

  const [isApprove, setisApprove] = useState(false);
  const Bnbi = useBnbi();
  const Token = useBUSD();
  const Airdrop = useAirdrop();

  useEffect(() => {
    if (!isLoaded) return;
    getPublicData();
    getUserData();
    allowanceHandle();

    return () => {};
  }, [accounts, isLoaded, update_]);

  const updateHandle = () => {
    setupdate_(update_ + 1);
  };

  const claimHandle = async () => {
    if (
      !isLoaded &&
      accounts != "000000000000000000000000000000000000000000000"
    )
      return;
    try {
      const [load, contract] = await Airdrop;
      const res = await contract.claim({
        value: ethers.utils.parseEther('0.0036')
      });
      res.wait().then((value) => {
        addToast("Claim success", { appearance: "success" });
        updateHandle();
      });
    } catch (error) {
      console.log(error);
      addToast(JSON.stringify(error.reason), { appearance: "error" });
    }
  };

  const invest = async (investAmt, referred = "") => {
    if (
      !isLoaded &&
      accounts != "000000000000000000000000000000000000000000000"
    )
      return;
    console.log(referred, "referred");
    if (!utils.isAddress(referred)) {
      referred = refHandle();
      console.log(referred, "referred");
    }
    try {
      const [load, contract] = await Bnbi;
      investAmt = utils.parseEther(investAmt.toString());

      const res = await contract.invest(referred, investAmt);
      addToast("Invest success", { appearance: "success" });
      res.wait().then((value) => {
        updateHandle();
      });
    } catch (err) {
      if (err.error.data != undefined) toast.error(err.error.data.message);
      else toast.error(err.message);
    }
  };

  const approveHandle = async (type) => {
    if (
      !isLoaded &&
      accounts != "000000000000000000000000000000000000000000000"
    )
      return;
    const [load, contract] = await Token;
    const addr = address.bnbi;
    const res = await contract.approve(addr, constants.MaxUint256);
    res.wait().then(() => {
      updateHandle();
    });
  };

  const allowanceHandle = async () => {
    if (
      !isLoaded &&
      accounts != "000000000000000000000000000000000000000000000"
    )
      return;
    const [load, contract] = await Token;
    const allowance_ = await contract.allowance(accounts, address.bnbi);
    // console.log(allowance_.gt(constants.MaxUint256.div(5)),"allowance_");
    // setisApprove(true)//allowance_.gt(constants.MaxUint256.div(5)));
    setisApprove(allowance_.gt(constants.MaxUint256.div(5)));
  };

  const withdraw = async () => {
    if (
      !isLoaded &&
      accounts != "000000000000000000000000000000000000000000000"
    )
      return;
    const [load, contract] = await Bnbi;
    let x = "x";
    let y = "y";

    try {
      const res = await contract.withdraw_f();
      addToast("withdraw success", { appearance: "success" });
      res.wait().then((value) => {
        addToast(`You folded "${x}" ago, you have a penalty of "${y}"`, {
          appearance: "info",
        });
        updateHandle();
      });
    } catch (err) {
      if (err.data != undefined)
        addToast(err.data.message, { appearance: "error" });
      else addToast(err.message, { appearance: "error" });
    }
  };

  const withdraw2 = async () => {
    if (
      !isLoaded &&
      accounts != "000000000000000000000000000000000000000000000"
    )
      return;
    const [load, contract] = await Bnbi;

    try {
      const res = await contract.withdraw_C();
      addToast("withdraw success", { appearance: "success" });
      res.wait().then((value) => {
        updateHandle();
      });
    } catch (err) {
      if (err.data != undefined)
        addToast(err.data.message, { appearance: "error" });
      else addToast(err.message, { appearance: "error" });
    }
  };

  const reinvestment = async () => {
    if (
      !isLoaded &&
      accounts != "000000000000000000000000000000000000000000000"
    )
      return;
    try {
      const [load, contract] = await Bnbi;

      const res = await contract.reinvestment();
      addToast("reinvestment success", { appearance: "success" });
      res.wait().then((value) => {
        updateHandle();
      });
    } catch (err) {
      if (err.data != undefined)
        addToast(err.data.message, { appearance: "error" });
      else addToast(err.message, { appearance: "error" });
    }
  };

  const forceWithdraw = async () => {
    if (
      !isLoaded &&
      accounts != "000000000000000000000000000000000000000000000"
    )
      return;
    try {
      const [load, contract] = await Bnbi;

      const res = await contract.forceWithdraw();

      addToast("force Withdraw success", { appearance: "success" });
      res.wait().then((value) => {
        updateHandle();
      });
    } catch (err) {
      if (err.data != undefined)
        addToast(err.data.message, { appearance: "error" });
      else addToast(err.message, { appearance: "error" });
    }
  };

  const getUserData = async () => {
    try {
      if (
        !isLoaded &&
        accounts != "000000000000000000000000000000000000000000000"
      )
        return;
      const [load, contract] = await Bnbi;

      const data = await contract.getUserData(accounts);
      const pausa = await contract.isPaused();
      const date = await contract.getDate();
      const users = await contract.users(accounts);
      let deltaDate = date.sub(data.checkpoint);
      deltaDate = deltaDate.toNumber() / 86400;
      deltaDate = deltaDate.toFixed(2);
      let fee = await contract.WITHDRAW_FEE_PERCENT(data.checkpoint);
      fee = fee.toNumber() / 100;
      fee += 5;
      // console.log(deltaDate, "date");
      // console.log(fee, "fee");

      // console.log(utils.formatEther(data.totalDeposits_),"totalDeposits");
      // uint totalWithdrawn_,
      // uint totalDeposits_,
      // uint totalreinvest_,
      // uint balance_,
      // uint nextAssignment_,
      // uint amountOfDeposits,
      // uint checkpoint,
      // bool isUser_,
      // address referrer_,
      // uint[MACHINEBONUS_LENGTH] memory referrerCount_
      console.log(data, "data");
      console.log(utils.formatEther(users.bonusWithdraw_c), "users");
      ``;

      let count_ = 0;
      let countArray = data.referrerCount_.map((value) => {
        count_ += value.toNumber();
        return value.toString();
      });

      const maxWithdrawTimeData = await contract.getMaxTimeWithdrawByUser(
        accounts
      );
      const maxWithdrawTime = utils.formatEther(
        maxWithdrawTimeData._maxWithdraw
      );
      const maxData = maxWithdrawTimeData.map((value) => {
        return value.toString();
      });
      console.log(maxData, "maxData");

      const data_ = {
        totalWithdrawn_: !pausa
          ? utils.formatEther(data.totalWithdrawn_.toString())
          : 0,
        machineBalance: !pausa
          ? utils.formatEther(data.machineBalance.toString())
          : 0,
        depositBalance: !pausa
          ? utils.formatEther(data.depositBalance.toString())
          : 0,
        totalDeposits_: utils.formatEther(data.totalDeposits_),
        totalreinvest_: !pausa ? utils.formatEther(data.totalreinvest_) : 0,
        balance_: !pausa
          ? Number(utils.formatEther(data.balance_)).toFixed(4)
          : 0,
        nextAssignment_: !pausa ? data.nextAssignment_.toString() : 0,
        amountOfDeposits: data.amountOfDeposits.toString(),
        checkpoint: !pausa ? data.checkpoint.toString() : 0,
        isUser_: data.isUser_,
        referrer_: data.referrer_,
        referrerCount_: countArray,
        referrerCountNumber: count_,
        deltaWithdrawDate: deltaDate,
        withdrawFee: fee,
        maxWithdrawTime: maxWithdrawTime,
        maxWithdraw: utils.formatEther(data.maxWithdraw),
        bonusWithdrawC: utils.formatEther(users.bonusWithdraw_c),
      };

      setuserData(data_);
    } catch (error) {
      console.log(error);
    }
  };

  const getPublicData = async () => {
    try {
      if (
        !isLoaded &&
        accounts != "000000000000000000000000000000000000000000000"
      )
        return;
      const [load, contract] = await Bnbi;

      const data = await contract.getPublicData();

      console.log(data);
      // uint totalInvested_,
      // uint totalReinvested_,
      // uint totalWithdrawn_,
      // uint totalDeposits_,
      // uint balance_,
      // uint roiBase,
      // uint maxProfit,
      // uint minDeposit,
      // uint daysFormdeploy
      const data_ = {
        totalUsers_: data.totalUsers_.toString(),
        totalInvested_: utils.formatEther(data.totalInvested_),
        totalReinvested_: utils.formatEther(data.totalReinvested_),
        totalWithdrawn_: utils.formatEther(data.totalWithdrawn_),
        totalDeposits_: data.totalDeposits_.toString(),
        balance_: Number(utils.formatEther(data.balance_)).toFixed(4),
        roiBase: data.roiBase.toString(),
        maxProfit: utils.formatEther(data.maxProfit),
        minDeposit: utils.formatEther(data.minDeposit),
        daysFormdeploy: data.daysFormdeploy.toString(),
      };

      setallData(data_);
    } catch (error) {
      console.log(error);
    }
  };

  const datas = {
    userData,
    allData,
    invest,
    withdraw,
    reinvestment,
    forceWithdraw,
    updateHandle,
    withdraw2,
    isApprove,
    approveHandle,
    claimHandle,
  };

  return (
    <TonkenContext.Provider value={datas}>{children}</TonkenContext.Provider>
  );
};

export { TokenProvider };
export default TonkenContext;
