import React, { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { Routes, Route } from "react-router-dom";
import InvestNow from "../components/InvestNow";
import Navbar from "../components/Navbar";
import { useSpinner } from "../context/SpinnerContext";
import Banner from "../components/Banner";
import PresalePrivada from "../components/PresalePrivada";
import PresalePublic from "../components/PresalePublic";
import PresalePublic2 from "../components/PresalePublic2";
import PriceToken from "../components/PriceToke";

const AppRouter = () => {
  const { isSpinnerShown, spinnerMessage, showSpinner, hideSpinner } =
    useSpinner();

  return (
    <>
      <Toaster
        position='top-right'
        toastOptions={{
          style: {
            background: "#363636",
            color: "#fff",
          },
          iconTheme: {
            primary: "#eab308",
            secondary: "#000",
          },
        }}
      />
      <div className='bg-gradient-to-b from-gray-700 via-gray-900 to-black'>
        <Routes>
          {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}

          <Route path='/' element={<InvestNow />} />
          <Route path='/presale-privad' element={<PresalePrivada />} />
          <Route path='/presale-public' element={<PresalePublic />} />
          <Route path='/presale-new' element={<PresalePublic2 />} />
        </Routes>
      </div>
    </>
  );
};
export default AppRouter;
