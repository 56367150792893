import { useMemo, useContext, useState } from "react";
import { Bnbi, BUSD, saleAbi, saleAbi2, AirdropAbi } from "./abiHelpers";
import Web3Context from "../context/Web3Context";
import { Contract, ethers } from "ethers";

export const address = {
  bnbi: "0x7E275333a6184b36D0FDe054b3a79F157C177246",
  busd: "0x55d398326f99059fF775485246999027B3197955",
  privateSale:"0x03C66EB15880681364C5C47583C6B1B8e93B0230",
  publicSale:"0x2125B46FF616d1227E89C3530f7CFf8D397aa2Ff",  
  newPresale: "0xfadac4129991bd599dce557826f7e14e4fd5ce15",
  airdrop: "0x766d6C36c8026bd58e5CB183F6F60c7e6BD4c334",
};

export const useBUSD = () => {
  const { accounts, isLoaded } = useContext(Web3Context);
  return useMemo(async () => {
    if (!isLoaded) return [false, null];
    const provider = await etherJSProvider();
    const signer = provider.getSigner();
    const contract = new Contract(address.busd, BUSD, signer);
    return [true, contract];
  }, [accounts, isLoaded]);
};


export const  usePrivateSale =async ()=>{
  const { accounts, isLoaded } = useContext(Web3Context);
  return useMemo(async() => {
    
    if(!isLoaded){
    console.log("not loaded");
    return [false,null]}
    try{
      
      const provider = await etherJSProvider();
    const signer = provider.getSigner()    
    const contract = new Contract(address.privateSale ,saleAbi,signer)          
    return [true, contract]
    }catch(e){
      console.log(e)
      return [false,null]
    }
    },[accounts,isLoaded])
}

export const  useAirdrop =async ()=>{
  const { accounts, isLoaded } = useContext(Web3Context);
  return useMemo(async() => {
    
    if(!isLoaded){
    console.log("not loaded");
    return [false,null]}
    try{
      
      const provider = await etherJSProvider();
    const signer = provider.getSigner()    
    const contract = new Contract(address.airdrop ,AirdropAbi,signer)          
    return [true, contract]
    }catch(e){
      console.log(e)
      return [false,null]
    }
    },[accounts,isLoaded])
}

export const usePublicSale = ()=>{
  const {accounts, isLoaded,connect} = useContext(Web3Context)        
  return useMemo(async() => {
    if(!isLoaded)
    return [false,null]
    try{
      const provider = await etherJSProvider();
    const signer = provider.getSigner()    
    const contract = new Contract(address.publicSale ,saleAbi2,signer)          
    return [true, contract]
    }catch(e){
      console.log(e)
      return [false,null]
    }
    },[accounts,isLoaded])
}

export const usePublicSaleNew = ()=>{
  const {accounts, isLoaded,connect} = useContext(Web3Context)        
  return useMemo(async() => {
    if(!isLoaded)
    return [false,null]
    try{
      const provider = await etherJSProvider();
    const signer = provider.getSigner()    
    const contract = new Contract(address.newPresale ,saleAbi2,signer)          
    return [true, contract]
    }catch(e){
      console.log(e)
      return [false,null]
    }
    },[accounts,isLoaded])
}


export const useBnbi = () => {
  const { accounts, isLoaded } = useContext(Web3Context);
  return useMemo(async () => {
    if (!isLoaded) return [false, null];
    const provider = await etherJSProvider();
    const signer = provider.getSigner();
    console.log(address.bnbi, "address.bnbi");
    const contract = new ethers.Contract(address.bnbi, Bnbi, signer);
    return [true, contract];
  }, [accounts, isLoaded]);
};

const etherJSProvider = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
  return provider;
};
