/* eslint-disable */
import React, { createContext, useState, useEffect } from 'react';
import PROVIDER from '../hooks/provider'
import {BigNumber,Contract,ethers} from 'ethers'

const Web3Context = createContext();


const Web3Provider = ({ children }) => {  
  const [accounts, setAccounts] = useState('000000000000000000000000000000000000000000000');
  const [err, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [update, setupdate] = useState(0);
  const [balanceOf_, setbalanceOf_] = useState(BigNumber.from('0'))
  
  const walletTest =""//"0xD95048b860edA18a335988D95f7A3dCF3CD63b91"
  const balanceOfHandle = async()=>{    
    if (!isLoaded && accounts!='000000000000000000000000000000000000000000000')
    return
    const provider =await etherJSProvider()
    const signer = provider.getSigner()
    // if(walletTest.length>0)
    //   signer = new ethers.VoidSigner(walletTest);
    const contract = new Contract(process.env.REACT_APP_TOKEN_MAT,IBEP20,signer)   
    const tokenBalance = await contract.balanceOf(accounts)    
    setbalanceOf_(ethers.utils.formatUnits(tokenBalance))
  }


const wallet =async () => {  
  try {    
      const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
      // Prompt user for account connections
      await provider.send("eth_requestAccounts", []);
      let  signer = provider.getSigner();
      if(walletTest.length>0)
      signer = new ethers.VoidSigner(walletTest);
      // console.log("Account222:", await signer.getAddress());
    
  return  await signer.getAddress()
  } catch (error) {
    return console.log("fallo");   
  }  
}

const etherJSProvider =async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum, "any");  
  return provider;
}

const enable =async () => {  
  try {    
      const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
      // Prompt user for account connections
      if(await provider.send("eth_requestAccounts", [])); 
      return true
  } catch (error) {
    return false
  }  
}



  const ethereumProvider = async () => {
    try {
      //console.log('ethereum',window.ethereum.isMetaMask);      
      
      if(window.ethereum.isMetaMask){
        //console.log("pasooooo",window.ethereum);
              const provider = window.ethereum                
              //  await provider.request({
                //  method: 'wallet_addEthereumChain',
                //  params:PROVIDER.DEV,
    //           })            
        }        
        
        const accounts_ = await wallet()
        console.log(accounts_);        
        setAccounts(accounts_);
    } catch (error) {
      console.log(error, 'ERRROR');
      setError(true);
    }
  };

  const legacyProvider = () => {
    // Use Mist/MetaMask's provider.
    console.log('legacyProvider');
    const web3 = window.web3;
    window.web3 = web3;
    let accounts_ = window.web3.eth.getAccounts();
    setAccounts(accounts_);
    console.log('Injected web3 detected.');
  };

  const extensionSetup = async () => {
    if (window.ethereum) {
      if(await enable()){
      await ethereumProvider();
      return true;}
    }
    // Legacy dapp browsers...
    else if (!!window.web3 && window.web3.isReady) {      
        legacyProvider();
        return true;      
    }
    // Fallback to localhost; use dev console port by default...
    else {
      /* const provider = new Web3.providers.HttpProvider(
          "http://localhost:9545"
        );
        const web3 = new Web3(provider);
        console.log("No web3 instance injected, using Local web3.");*/
      return false;
    }
  };

  const OnAcountsChange = async (time) => {
    window.ethereum.on('accountsChanged', async () => {
      //On change Address
      let accounts_ = await wallet();
      setAccounts(accounts_);
      console.log(`Account changed: ${accounts[0]}`);
    });
    ethereum.on('chainChanged', async(chainId) => {
      // Handle the new chain.
      // Correctly handling chain changes can be complicated.
      // We recommend reloading the page unless you have good reason not to.
      //const chainId = await ethereum.request({ method: 'eth_chainId' });            
          // if(chainId != process.REACT_APP_CHAIN_ID)
          //   setIsLoaded(false);
          // else 
            setIsLoaded(true);
            
      //window.location.reload();
    });
    window.ethereum.on('disconnect', () => {
      //On disconect
      setIsLoaded(false);
      console.log('disconnect');
    });

    clearInterval(time);
  };

  const updateHandler = () => {
    setupdate(update + 1);
  };

  
  //load web3
  useEffect(() => {      
    let time;    
    if(window?.ethereum?.isTrust)
    time = setTimeout(async () => {           
      if (!isLoaded) {
        const state = await extensionSetup();
        if (state) {            
          const chainId = await ethereum.request({ method: 'eth_chainId' });      
          console.log(chainId);               
          
          setIsLoaded(true);
          
          clearInterval(time);
        } else {
          setError(true);
          clearInterval(time);
        }
      } else clearInterval(time);
    }, 4000);
    else
     window.addEventListener('load', function (event) {                       
       time = setTimeout(async () => {           
         if (!isLoaded) {
           const state = await extensionSetup();
           if (state) {            
             const chainId = await ethereum.request({ method: 'eth_chainId' });      
             console.log(chainId);               
             
             setIsLoaded(true);
             
             clearInterval(time);
           } else {
             setError(true);
             clearInterval(time);
           }
         } else clearInterval(time);
       }, 2000);
     });
 
     return () => {
       clearTimeout(time);
     };
   }, []);

   
  // on wallet change
  useEffect(() => { 
    const time = setTimeout(async () => {
      if (isLoaded) {
        await OnAcountsChange(time);
      }
    }, 600);

    return () => {
      clearTimeout(time);
    };
  }, [isLoaded]);

  //
  useEffect(() => {
  
    const time = setTimeout(async () => {
      if (!isLoaded) return;      
//      balanceOfHandle()  
    });    
    
    return () => {
      clearTimeout(time);
    };
  }, [accounts,isLoaded,update]);



  const datas = { accounts, isLoaded, extensionSetup, err,balanceOf_,setupdate,updateHandler,etherJSProvider };

  return <Web3Context.Provider value={datas}>{children}</Web3Context.Provider>;
};

export { Web3Provider };
export default Web3Context;
